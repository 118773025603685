import {IConfig} from "@/config/types";

const config: IConfig = {
  version: "v1.2.0",
  project: "recovered",
  timeOut: 60 * 60 * 1000,
  platformName: "乡爱康复体验中心管理后台",
  company: "深圳市榆晟科技有限责任公司",
  uploadUrl: "/party/oss/ordinaryUpload",
  fragmentationUrl: "/party/oss/burstUpload",
  downloadUrl: "/api/party/oss/download",
  videoDownloadUrl:'/api/party/oss/play/video'
}

export const WEB_HOST = `${location.protocol}//${location.host}`

export default config
